import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Button, Overlay, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { navigate } from 'gatsby';

import SEO from '../../components/Seo';
import Loader from '../../components/Loader';
import PrivateRoute from '../../components/auth/protected';
import OrderTimeline from '../../components/status/Timeline';
import Details from '../../components/status/Details';
import OrderItems from '../../components/status/OrderItems';
import OrderDetailPDF from '../../components/orders/OrderDetailPDF';
import { getOrderById } from '../../utils/api';
import { getCreditOrder } from '../../state/checkout/actions';

const CREDIT_KEY = process.env.GATSBY_CREDIT_KEY;
const CREDIT_TOKEN = process.env.GATSBY_CREDIT_SECRET;
const CREDIT_KEY_URL = process.env.GATSBY_CREDIT_KEY_API_URL;

const OrderDetail = ({
  orderId,
  user,
  token,
  location: { state },
  getCreditOrder,
  ckOrder,
}) => {
  const [order, setOrder] = useState(null);
  const [orderLoaded, setOrderLoaded] = useState(state ? true : false);

  const [flag, setFlag] = useState(false);

  useEffect(() => {
    const fetchOrderById = async (id, token) => {
      setOrderLoaded(false);
      const res = await getOrderById(id, token);
      setOrder(res);
      setOrderLoaded(true);
    };
    if (!order) fetchOrderById(orderId, token);
  }, [orderId, token]);

  useEffect(() => {
    var timer = setTimeout(() => {
      setFlag(true);
    }, 2000);
  }, []);

  let creditOrderId;
  if (order && order.notes) {
    if (order.notes.includes('CK-')) {
      const splitted = order.notes.split(':');
      creditOrderId = splitted[1];
    }
  }

  const findOrderUrl = `${CREDIT_KEY_URL}/find_order?public_key=${CREDIT_KEY}&shared_secret=${CREDIT_TOKEN}&id=${creditOrderId ? creditOrderId : ''
    }`;

  const getCreditKeyOrder = async () => {
    const orderCredit = await fetch(findOrderUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
    const data = orderCredit ? await orderCredit.json() : '';
    getCreditOrder(data);
    return data;
  };

  const completeOrderUrl = `${CREDIT_KEY_URL}/update_order?public_key=${CREDIT_KEY}&shared_secret=${CREDIT_TOKEN}`;

  const completeCreditKeyOrder = async () => {
    if (ckOrder) {
      const completedOrder = await fetch(completeOrderUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          id: ckOrder ? ckOrder.id : '',
          merchant_order_id: ckOrder ? ckOrder.merchant_order_id : '',
          merchant_status: ckOrder ? ckOrder.merchant_status : '',
          cart_items: ckOrder ? ckOrder.items : [],
          charges: ckOrder ? ckOrder.charges : '',
          shipping_address: ckOrder ? ckOrder.shipping_address : '',
        }),
      });

      const data = completedOrder ? await completedOrder.json() : '';
      return data;
    }
  };

  useEffect(() => {
    if (order && order.notes && order.notes.includes('CK-')) {
      getCreditKeyOrder();
    } else {
      getCreditOrder(null);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (ckOrder && order.status.name === 'Delivery') {
        completeCreditKeyOrder();
      }
    }, 700);
  }, [ckOrder]);

  const target = useRef(null);

  useEffect(() => {
    if (user && order && user.erp_id !== order.customer_id) {
      navigate('/orders');
    }
  }, [order, user]);

  return (
    <PrivateRoute showLock>
      <SEO title='Order Status Page' />
      <Row
        className='text-center'
        style={{ marginTop: '5rem', marginBottom: '1.5rem' }}
      >
        <div
          className='col-sm-3 justify-content-start'
          style={{ marginTop: '10px' }}
        >
          <Button
            // href='/orders'
            style={{ backgroundColor: 'white', color: '#7c6262' }}
            onClick={() => {
              navigate('/orders');
            }}
          >
            <i className='fas fa-arrow-left' style={{ marginRight: '5px' }} />
            Back
          </Button>
        </div>
        <div className='col-sm-5 text-center'>
          <div style={{ fontSize: '38px' }} className='text-center'>
            Order Status
          </div>
        </div>
        <div className='col-sm-4 text-center'>
          {order && order.shipping_tracking_url && (
            <>
              {order && (
                <Overlay
                  target={target.current}
                  show={order.status.name !== 'Delivery'}
                  placement='top'
                >
                  {(props) => (
                    <Tooltip id='overlay-example' {...props}>
                      You'll be able to track your order once it is processed!
                    </Tooltip>
                  )}
                </Overlay>
              )}
              <button
                onClick={() => {
                  if (window) {
                    window.open(order.shipping_tracking_url);
                  }
                }}
                style={
                  order.status.name !== 'Delivery'
                    ? {
                      backgroundColor: '#d3d3d3',
                      color: 'gray',
                      padding: '5px',
                      marginTop: '10px',
                      borderRadius: '8px',
                      fontSize: '18px',
                      fontWeight: 'bold',
                    }
                    : {
                      backgroundColor: 'white',
                      color: '#7c6262',
                      padding: '5px',
                      marginTop: '10px',
                      border: 'none',
                      borderRadius: '8px',
                      fontSize: '19.6px',
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                    }
                }
                ref={target}
                disabled={order.status.name !== 'Delivery'}
              >
                Click here to track your order!
              </button>
            </>
          )}
        </div>
      </Row>
      <Row className='detail-timeline'>
        {order ? (
          <>
            <OrderTimeline
              currentStatus={
                order.status && order.status.gildform_name
                  ? order.status.gildform_name
                  : order.status.name
              }
            />
            <Details currentStatus={order.status} />
            <Row>
              <Col className='invoice-inside'>
                <img
                  style={{ width: '32px', height: '32px' }}
                  src='/images/csv.png'
                  alt='Csv icon'
                />
                {order && flag && (
                  <PDFDownloadLink
                    document={<OrderDetailPDF data={{ order, user }} />}
                    fileName={`Order #${order.id}.pdf`}
                  >
                    Invoice
                  </PDFDownloadLink>
                )}
              </Col>
            </Row>
            <OrderItems orderItems={order.orderItems || []} />
          </>
        ) : (
          <Loader msg='Loading Order Details. Please wait' />
        )}
      </Row>
    </PrivateRoute>
  );
};

const mapStateToProps = (state) => ({
  user: state.login.auth && state.login.auth.profile,
  token: state.login.auth && state.login.auth.auth.token,
  ckOrder: state.checkout && state.checkout.credit_key_order,
});

const mapDispatchToProps = (dispatch) => ({
  getCreditOrder: (data) => dispatch(getCreditOrder(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
